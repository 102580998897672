import { useStateContext } from "../../context";

function useMember() {
  const [{ member }, dispatch] = useStateContext();

  function setUser(newMember) {
    dispatch({
      type: "SET_MEMBER",
      payload: newMember,
    })
  }

  return [member, setUser];
}

export default useMember;
