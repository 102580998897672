import { gql } from "@apollo/client";
import {MEMBER_FRAGMENT, KITAKERJA_USER_FRAGMENT, MEMBER_PROFILE_COMPLETION_FRAGMENT} from "../common";

export const GET_USER_LISTING = gql`
  query getUserListing($page: Int, $limit: Int, $name: String) {
    user {
      users(page: $page, limit: $limit, name: $name) {
        page
        limit
        total
        name
        nodes {
          id
          name
          created_at
          member {
            ...Member
          }
          kitakerja_user {
            ...KitakerjaUser
          }
        }
      }
    }
  }
  ${MEMBER_FRAGMENT}
  ${KITAKERJA_USER_FRAGMENT}
`;

export const GET_USER_DETAIL = gql`
  query getUserDetail($id: Int!) {
    user {
      user(id: $id) {
        id 
        name
        email
        member {
          id
          name
          phone
          email
          gender
          summary
          address
          image
          birthdate
          status
          image_file {
            path
            default
            sizes {
              small
            }
          }
          visibility {
            id
            name
          }
        }
        kitakerja_user {
          id
          user_type
          user_id
          is_private
          is_premium
          is_followed
          follower_count
          following_count
          user {
            id
            name
            email
          }
          employer {
            id
            name
            email
          }
        }
      }
    }
  }
`;

export const GET_USER_DATA = gql`
  query GetUserData {
    me {
      ...KitakerjaUser
    }
  }
  ${KITAKERJA_USER_FRAGMENT}
`;

export const GET_USER_PROGRESS = gql`
  query GetUserProgress {
    me {
      id
      user {
        id
        member {
          id
          profile_completion {
            ...MemberProfileCompletion
          }
        }
      }
    }
  }
  ${MEMBER_PROFILE_COMPLETION_FRAGMENT}
`;