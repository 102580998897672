import { useStateContext } from "../../context";

const useMe = () => {
  const [{ me }, dispatch]: any = useStateContext();

  function setMe(meData: any) {
    dispatch({
      type: "SET_ME",
      payload: meData
    })
  }

  return [me, setMe];
}

export default useMe;
