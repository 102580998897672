import { gql } from "@apollo/client";

export const MEMBER_PROFILE_COMPLETION_FRAGMENT = gql`
  fragment MemberProfileCompletion on MemberProfileCompletion {
    all_complete
    total_step
    step_completion
    educations
    image
    members_skills
    phone_verify
    profile
    resume
    salary
    summary
    email_verify
  }
`;

export const MEMBER_FRAGMENT = gql`
  fragment Member on Member {
    id
    image
    name
    phone
    email
    address
    birthdate
    summary
    step_completion
    visibility_id
    visibility {
      id
      name
    }
    user_id
    my_wallet
    code_referral
    status
    citizenship_card_verification_status
    gender
    countries_id
    expected_salary_lowest
    expected_salary_highest
    first_level_administrative_divisions_id
    second_level_administrative_divisions_id
    image_file {
      path
      default
      sizes {
        small
        medium
        large
      }
    }
    profile_completion {
      ...MemberProfileCompletion
    }
  }
  ${MEMBER_PROFILE_COMPLETION_FRAGMENT}
`;

export const USER_FRAGMENT = gql`
  fragment User on User {
    id
    name
    email
    member {
      ...Member
    }
  }
  ${MEMBER_FRAGMENT}
`;

export const COMPANY_FRAGMENT = gql`
  fragment Company on Company {
    id
    size
    name
    descriptions
    logo
    banner
    verified
    employers_id
    map_lat
    map_lng
    business_permit
    about
    address
    status
    logo_file {
      sizes {
        small
      }
    }
    extended_info {
      id
      company_id
      phone
      email
      email_hrd
      industry_types_id
      industry_types {
        id
        name
      }
      industry_fields_id
      industry_fields {
        id
        name
      }
    }
  }
`;

export const EMPLOYER_FRAGMENT = gql`
  fragment Employer on Employer {
    id
    name
    email
    image_serve {
      sizes {
        small
      }
    }
    company {
      ...Company
    }
  }
  ${COMPANY_FRAGMENT}
`;

export const KITAKERJA_USER_FRAGMENT = gql`
  fragment KitakerjaUser on KitakerjaUser {
    id
    user_type
    is_private
    is_premium
    is_followed
    user {
      ...User
    }
    employer {
      ...Employer
    }
  }
  ${USER_FRAGMENT}
  ${EMPLOYER_FRAGMENT}
`;
