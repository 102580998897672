import { useEffect, useState } from "react";
import { isBrowser } from "../../utils";

const useWindowSize = () => {
  function getDefaultValue() {
    if (isBrowser()) {
      return {
        width: window.innerWidth,
        height: window.innerHeight
      };
    }

    return {
      width: 0,
      height: 0
    };
  }
  
  const [windowSize, setWindowSize] = useState({
    ...getDefaultValue()
  });

  useEffect(() => {
    
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }

    window.addEventListener("resize", handleResize);

    handleResize();
 
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

export default useWindowSize
