import classnames from "classnames/bind";
import Image from "next/image";
import Link from "next/link";
import styles from "../../../styles/Layout.module.scss";
import InstagramIcon from "@pmberjaya/kitakerja-icons/build/Instagram";
import FacebookIcon from "@pmberjaya/kitakerja-icons/build/Facebook";
import EnvelopeIcon from "@pmberjaya/kitakerja-icons/build/Envelope";
import WhatsappIcon from "@pmberjaya/kitakerja-icons/build/Whatsapp";
import useTranslation from "next-translate/useTranslation";
import React from "react";

const socialMediaDatas = [
  { icon: <InstagramIcon />, link: "https://www.instagram.com/kitakerjacom" },
  { icon: <FacebookIcon />, link: "https://www.facebook.com/kitakerja.id" },
  { icon: <EnvelopeIcon />, link: "mailto:cs@kitakerja.com" },
  { icon: <WhatsappIcon />, link: "https://api.whatsapp.com/send/?phone=%2B6281276168350&text&app_absent=0" },
];

const cx = classnames.bind(styles);

const Footer: React.FC = () => {
  const { t } = useTranslation("common");

  return (
    <footer className={cx("footer", "bg-white")}>
      <div className="border-bottom">
        <div className="container">
          <div className="row py-5 text-capitalize">
            <div className="col-md-3 d-flex flex-column mb-5">
              <div className="mb-3 d-flex justify-content-center">
                <Image
                  width={160}
                  height={45}
                  src="/images/kitakerja.svg"
                  alt="kitakerja"
                />
              </div>
              <div className="d-flex gap-2 justify-content-center flex-wrap">
                {socialMediaDatas.map((media, index) => (
                  <Link key={index} href={media.link}>
                    <a
                      target="_blank"
                      className="btn btn-dark p-2 rounded-circle d-flex align-items-center justify-content-center"
                    >
                      {media.icon}
                    </a>
                  </Link>
                ))}
              </div>
            </div>
            <div className="col-md-6 mb-3">
              <h5 className="fw-bold fs-6">{t("learn more")}</h5>
              <div className="row">
                <Link href={`/privacy-policy`}>
                  <a className="col-6 mb-3">{t("privacy & policy")}</a>
                </Link>
                <Link href={`/about-us`}>
                  <a className="col-6 mb-3">{t("about us")}</a>
                </Link>
                <Link href={process.env.SUPPORT_URL || "#"}>
                  <a className="col-6 mb-3">{t("contact us")}</a>
                </Link>
                <Link href={"https://blog.kitakerja.com"}>
                  <a className="col-6" target="_blank">
                    {t("articles")}
                  </a>
                </Link>
              </div>
            </div>
            <div className="col-md-3 mb-3">
              <h5 className="fw-bold fs-6">{t("company")}</h5>
              <div className="row">
                <Link href={`${process.env.COMPANY_BASE_URL}`}>
                  <a className="col">{t("publish vacancy")}</a>
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p className="text-center py-2 m-0 bg-white border-bottom">
        <small>
          Copyright &copy;{new Date().getFullYear()} - JOVASOFTWARE (PT PUNDI
          MAS BERJAYA)
        </small>
      </p>
    </footer>
  );
};

export default Footer;
