import { useLazyQuery } from "@apollo/client";
import { useRouter } from "next/router";
import React, { useEffect, useState } from "react";
import { decode } from "../../functions/linkFormat";
import { REQUEST_SUGGESTIONS } from "../../graphql/ListingJob/query";
import useDebounce from "../../hooks/debounce";
import usePosthog from "../../utils/posthog";
import SearchList from "../ListingJob/search";

function JobSearch() {
  const [searchValue, setSearchValue] = useState("");
  const searchValueDebounce = useDebounce(searchValue, 1000);
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const { param } = router.query;
  const { captureJobSearch } = usePosthog();

  const [getSuggestions, { data, loading: recomendationLoading }] =
    useLazyQuery(REQUEST_SUGGESTIONS);

  useEffect(() => {
    if (searchValueDebounce) {
      if (searchValueDebounce !== " ") {
        getSuggestions({
          variables: {
            keyword: searchValueDebounce,
          },
        });
      }
    }
  }, [searchValueDebounce]);

  const handleSearch = (value) => {
    router.push(`/list/job/${encodeURI(value)}`);
  };

  const handleSearchListSubmit = (event) => {
    event.preventDefault(searchValue);
    handleSearch(searchValue);
  };

  useEffect(() => {
    if (param) {
      setSearchValue(param);
      captureJobSearch({ keyword: decode(param) });
    }
  }, [router.query]);

  return (
    <div>
      <SearchList
        handleSearchListSubmit={handleSearchListSubmit}
        data={data}
        open={open}
        setOpen={setOpen}
        setSearchValue={setSearchValue}
        searchValue={searchValue}
        loading={recomendationLoading}
      />
    </div>
  );
}

export default JobSearch;
