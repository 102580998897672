import React, { useEffect } from "react";
import Cookies from "universal-cookie";
import useMember from "../../hooks/member";
import useUser from "../../hooks/user";
import { useLazyQuery } from "@apollo/client";
import { GET_USER_DATA } from "../../graphql/User";
import Header from "./header";
import classnames from 'classnames/bind'
import styles from '../../styles/Layout.module.scss';
import Footer from "./footer";
import useMe from "../../hooks/me";
import usePosthog from "../../utils/posthog";

const cx = classnames.bind(styles)

const Layout: React.FC = (props: any) => {
  const cookies = new Cookies();
  const { children } = props;

  const accessToken = cookies.get("access_token");

  const [, setMember] = useMember();
  const [, setUser] = useUser();
  const [, setMe] = useMe();

  const [getUserData, { data }] = useLazyQuery(GET_USER_DATA);
  const { identify, getDistinctId } = usePosthog();

  useEffect(() => {
    if (data) {

      if (getDistinctId() != data?.me?.id) {
        identify(data?.me?.id, { email: data?.me?.user?.member?.email });
      }

      setMe(data?.me)
      setMember(data?.me?.user?.member)
      setUser(data?.me?.user)
    }
  }, [data])

  const isLogin = !!accessToken;

  useEffect(() => {
    if (accessToken) {
      getUserData();
    }
  }, [accessToken]);

  return (
    <>
      <Header
        isLogin={isLogin}
      />
      <main className={cx('main')}>
        {children}
      </main>
      <Footer />
    </>
  );
}

export default Layout;
