import Image from "next/image";
import Link from "next/link";
import classnames from "classnames/bind";
import styles from "../../../styles/Layout.module.scss";
import useTranslation from "next-translate/useTranslation";
import dynamic from "next/dynamic";
import Hide from "@pmberjaya/kitakerja-ui/build/Components/Hide";
import { useRouter } from "next/dist/client/router";
import React from "react";
import useWindowSize from "../../../hooks/useWindowSize";
import useMember from "../../../hooks/member";
import JobSearch from "../../JobSeach";

const UserButton = dynamic(() => import("../../common/userButton"), {
  ssr: false,
});

const MainNav = dynamic(() => import("./nav"), {
  ssr: false,
});

const cx = classnames.bind(styles);

interface HeaderProps {
  isLogin: boolean;
}

const Header: React.FC<HeaderProps> = (props) => {
  const { isLogin } = props;
  const { t } = useTranslation("common");

  const [member] = useMember();

  const router = useRouter();

  const windowSize = useWindowSize();

  return (
    <header className={cx("header")}>
      <div className="container-lg d-flex">
        <div className="d-flex align-items-center flex-grow-1">
          <h1 className="m-0 me-4">
            <Link href={"/"}>
              <a className="d-flex align-items-center">
                <Image
                  src={"/images/kitakerja.svg"}
                  width="150px"
                  height="40px"
                  alt="Kitakerja"
                  layout="fixed"
                />
              </a>
            </Link>
          </h1>
          <Hide on={windowSize.width < 768}>
            <JobSearch />
          </Hide>
          <Hide
            on={isLogin || router.pathname !== "/" || windowSize.width < 500}
          >
            <nav className={cx("nav")}>
              <Hide on={windowSize.width < 768}>
                <div className="d-flex align-items-center">
                  {/* <Link href={`${process.env.BASE_URL || "#"}/list/job`}>
                  <a className="text-decoration-none">{t("jobs")}</a>
                </Link> */}
                  {/* <Link href={"https://blog.kitakerja.com"}>
                  <a target="_blank" className="text-decoration-none">
                  {t("articles")}
                  </a>
                </Link> */}
                  <Link href={process.env.COMPANY_BASE_URL || "#"}>
                    <a target="_blank" className="text-decoration-none">
                      {t("companies")}
                    </a>
                  </Link>
                </div>
              </Hide>
            </nav>
          </Hide>
        </div>
        <div className="d-flex">
          <Hide on={isLogin ? !member : false}>
            <MainNav />
          </Hide>
          <Hide on={!isLogin || !member}>
            <div className="border bg-dark border-light my-2 mx-1"></div>
            <UserButton />
          </Hide>
        </div>
      </div>
    </header>
  );
};

export default Header;
