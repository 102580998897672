export function encode(str) {
  let code = str?.toLowerCase().trim().replace(/([.|\s|%|/|'|,])+/g, "-");
  return encodeURI(code);
}

export function decode(code) {
  const string = code.toLowerCase().split("-").join(" ");

  return decodeURI(string);
}
