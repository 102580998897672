import { gql } from "@apollo/client";
import { LISTING_JOB_NODES_FRAGMENT } from "../fragment";

export const GET_LISTING_JOB = gql`
  query getListingJob(
    $keyword: String, 
    $page: Int!, 
    $featured: Boolean,
    $filter: JobSearchFilter  
  ) {
    job {
      search(
        keyword: $keyword, 
        page: $page, 
        featured: $featured,
        filter: $filter
      ) {
        keyword
        page
        request_id
        nodes {
          ...Job
        }
      }
    }
  }
  ${LISTING_JOB_NODES_FRAGMENT}
`;

export const GET_EMPLOYMENT_TYPE = gql`
  query getEmploymentType {
    employment_type {
      employment_types {
        id
        name
      }
    }
  }
`;

export const REQUEST_SUGGESTIONS = gql`
  query requestSuggestions($keyword: String) {
    job {
      suggestion(keyword: $keyword)
    }
  }
`;

export const GET_JOB_LISTING_FILTER_DATA = gql`
  query JobListingFilterData {
    employment_type {
      employment_types {
        id
        name
      }
    }
    company{
      company_listing(require_open_jobs:true, limit: 5) {
        require_open_job
        nodes {
          id
          name
        }
      }
    }
  }
`;

export const GET_COMPANY_SEARCH_FILTER_DATA = gql`
  query GetCompanySearchFilterData($keyword: [String]) {
    company {
      search(
        page: 1,
        limit: 10,
        filter: {
          keyword: $keyword
        }
      ) {
        filter {
          keyword
        }
        nodes {
          id
          name
        }
      }
    }
  }
`;