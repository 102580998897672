import { gql } from "@apollo/client";
import { ATTAINMENT_FRAGMENT, EMPLOYMENT_TYPES_FRAGMENT, JOB_FUNCTION_FRAGMENT, LANGUAGE_FRAGMENT, LOCATION_FRAGMENT } from "../../Job/Fragment";
import { COMPANY_FRAGMENT } from "../../common";

export const LISTING_JOB_NODES_FRAGMENT = gql`
  fragment Job on Job {
    id
    title
    job_functions_id
    employment_types_id
    employers_id
    companies_id
    post_date
    shifts_id
    job_levels_id
    lowest_salary
    highest_salary
    descriptions
    attaintments_id
    languages_id
    lang_proviciencies_id
    total_slots
    status
    edu_focuses_id
    cloudtalent_id
    expire_at
    created_at
    featured
    position_id
    position_type_id
    has_applicants
    is_applied_by_current_logged_in_user
    application_status_applied_by_current_logged_in_user
    job_function {
      ...JobFunction
    }
    language {
      ...Language
    }
    attainment {
      ...Attaintment
    }
    company {
      ...Company
    }
    employment_types {
      ...EmploymentType
    }
    location {
      ...CompanyLocation
    }
  }
  ${JOB_FUNCTION_FRAGMENT}
  ${LANGUAGE_FRAGMENT}
  ${ATTAINMENT_FRAGMENT}
  ${COMPANY_FRAGMENT}
  ${EMPLOYMENT_TYPES_FRAGMENT}
  ${LOCATION_FRAGMENT}
`;
