import { gql } from "@apollo/client";

export const LANGUAGE_FRAGMENT = gql`
  fragment Language on Language {
    id
    name
  }
`;

export const JOB_FUNCTION_FRAGMENT = gql`
  fragment JobFunction on JobFunction {
    id
    name
  }
`;

export const ATTAINMENT_FRAGMENT = gql`
  fragment Attaintment on Attaintment {
    id
    name
    score
  }
`;

export const EDU_FOCUS_FRAGMENT = gql`
  fragment EduFocus on EduFocus {
    id
    name
  }
`;

export const JOB_LEVELS_FRAGMENT = gql`
  fragment JobLevel on JobLevel {
    id
    name
  }
`;

export const EMPLOYMENT_TYPES_FRAGMENT = gql`
  fragment EmploymentType on EmploymentType {
    id
    name
  }
`;

export const SHIFTS_FRAGMENT = gql`
  fragment Shift on Shift {
    id
    name
  }
`;

export const POSTION_TYPE_FRAGMENT = gql`
  fragment PositionType on PositionType {
    id
    name
  }
`;

export const SKILL_FRAGMENT = gql`
  fragment Skill on Skill {
    id
    name
    skills_categories_id
    skill_category {
      id
      name
    }
  }
`;

export const JOB_SKILLS_FRAGMENT = gql`
  fragment JobSkill on JobSkill {
    id
    jobs_id
    skills_id
    skill {
      ...Skill
    }
  }
  ${SKILL_FRAGMENT}
`;

export const COUNTRY_FRAGMENT = gql`
  fragment Country on Country {
    id
    name
    initial
  }
`;

export const FIRST_LEVEL_ADMINISTRACTIVE_DIVISION_FRAGMENT = gql`
  fragment FirstLevelAdministrativeDivision on FirstLevelAdministrativeDivision {
    id
    name
    countries_id
  }
`;

export const SECOND_LEVEL_ADMINISTRACTIVE_DIVISION_FRAGMENT = gql`
  fragment SecondLevelAdministrativeDivision on SecondLevelAdministrativeDivision {
    id
    name
    first_level_administrative_divisions_id
  }
`;

export const LOCATION_FRAGMENT = gql`
  fragment CompanyLocation on CompanyLocation {
    id
    name
    address
    companies_id
    countries_id
    first_level_administrative_divisions_id
    second_level_administrative_divisions_id
    is_main
    status
    country {
      ...Country
    }
    first {
      ...FirstLevelAdministrativeDivision
    }
    second {
      ...SecondLevelAdministrativeDivision
    }
  }
  ${COUNTRY_FRAGMENT}
  ${FIRST_LEVEL_ADMINISTRACTIVE_DIVISION_FRAGMENT}
  ${SECOND_LEVEL_ADMINISTRACTIVE_DIVISION_FRAGMENT}
`;
