import { useStateContext } from "../../context";

const useUser = () => {
  const [{ user }, dispatch]: any = useStateContext();

  function setUser(userData: any) {
    dispatch({
      type: "SET_USER",
      payload: userData
    })
  }

  return [user, setUser];
}

export default useUser;
