import Autocomplete from "@material-ui/lab/Autocomplete";
import parse from "autosuggest-highlight/parse";
import match from "autosuggest-highlight/match";
// import Button from "@pmberjaya/kitakerja-ui/build/Components/Button";
import styles from "../listJob.module.css";
// import useTranslation from "next-translate/useTranslation";

function SearchList(props) {
  const {
    handleSearchListSubmit,
    // options,
    // setOptions,
    data,
    open,
    setOpen,
    setSearchValue,
    searchValue,
    loading,
    // setLoading,
  } = props;
  // const { t } = useTranslation("common");
  return (
    <form onSubmit={handleSearchListSubmit}>
      <div className="w-100">
        <Autocomplete
          id="asynchronous-demo"
          // disabled={loading}
          open={open}
          className={styles.autocompleteSearch}
          onOpen={() => {
            setOpen(true);
            // setOptions([...options]);
            // setLoading(false);
          }}
          onClose={() => {
            setOpen(false);
          }}
          getOptionSelected={(option, value) => {
            option === value;
          }}
          getOptionLabel={(option) => option}
          options={data?.job?.suggestion || []}
          loading={loading}
          renderOption={(option) => {
            const matches = match(option, searchValue);
            const parts = parse(option, matches);
            return (
              <div>
                {parts.map((part, index) => (
                  <span
                    key={index}
                    style={{ fontWeight: part.highlight ? 700 : 400 }}
                  >
                    {part.text}
                  </span>
                ))}
              </div>
            );
          }}
          onChange={(event, newValue) => {
            setSearchValue(newValue);
          }}
          renderInput={(params) => (
            <div ref={params.InputProps.ref}>
              <input
                {...params.inputProps}
                onChange={(e) => {
                  setSearchValue(e.target.value);
                  if (!open) {
                    setOpen(true);
                  }
                }}
                value={searchValue}
                className={styles.searchBoxListJob}
                loading={loading}
                type="text"
                name="search"
                autoComplete="off"
                placeholder="Cari pekerjaan"
              />
            </div>
          )}
        />
        {/* <Button
          variant="contained"
          className={styles.searchBtnListJob}
          loading={loading}
          type="submit"
        >
          {t("search")}
        </Button> */}
      </div>
    </form>
  );
}

export default SearchList;
